<template>
  <client-only>
    <VuePdfPreview :style="{height:height}" :source="link" :loading-text="loadingText" :rendering-text="renderingText" :controls="controls" @rendered="pagesRendered" @loading-failed="loadingFailed" @rendering-failed="renderingFailed"/>
  </client-only>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      default () {
        return ''
      }
    },
    height: {
      type: String,
      default () {
        return '50vh'
      }
    }
  },
  data () {
    return {
      controls: [
        // 'download',
        // 'print',
        // 'rotate',
        'zoom',
        'catalog',
        'switchPage'
      ],
      loadingText: 'Đang tải dữ liệu ...',
      renderingText: 'Đang tải dữ liệu ...'
    }
  },
  methods: {
    pagesRendered () {
      const viewerContainer = document.getElementById('viewerContainer')
      if (viewerContainer) {
        viewerContainer.scrollTop = 0
        viewerContainer.classList = 'scrollbar'
      }
    },
    loadingFailed () {
      this.$notify({
        type: 'error',
        message: 'Không tải được file'
      })
    },
    renderingFailed () {
      this.$notify({
        type: 'error',
        message: 'Không xem được file'
      })
    }
  }
}
</script>

<style lang="scss">
  .pdf-viewer{
    max-width: 100%;
    overflow: hidden;
    .header__preview{
      .start{
        .title{
          display: none;
        }
      }
      .center{
        #pageselector{
          width:40px;
        }
      }
    }
  }
</style>
