<template>
  <div class="answer-item position-relative">
    <div class="time-line d-none d-lg-block position-absolute h-100"></div>
    <div class="time-line-dot d-none d-lg-flex position-absolute rounded-circle align-items-center justify-content-center"></div>
    <div class="time-line-dot-end position-absolute d-none align-items-center justify-content-center"></div>
    <div class="comment">
      <div class="user-info form-data">
        <img :src="item.owner.avatar || NoAvatar" class="avatar rounded-circle" alt="avatar"/>
        <div class="content">
          <div class="user-name text-break">{{ item.owner.fullName }}
            <span v-if="item.is_guru === 1" class="font-weight-normal is-guru">
              <img src="~/mlearn/icons/answer/is-guru.svg"/>
              Gia sư
            </span>
          </div>
          <span class="time-ago">{{ item.created_at | ralativeTime }}</span>
        </div>
        <div class="guru-verify" v-if="item.guru_maked">
          <div class="d-flex align-items-center">
            <img src="~/mlearn/icons/answer/guru-verify.svg" alt="verify" class="mr-2"/>
            <span class="d-none d-lg-block">Được gia sư xác nhận</span>
            <span class="d-block d-lg-none">Xác nhận</span>
          </div>
        </div>
      </div>
      <div class="content-answer" v-if="item.content">
        <vue-mathjax :formula="item.content" :safe="false"></vue-mathjax>
      </div>
      <div v-if="item.image && item.image.length" class="image-answer">
        <viewer :images="item.image" :options="options" class="image-container pointer">
          <img v-for="imageItem in item.image" :src="imageItem" :key="imageItem">
        </viewer>
      </div>
      <div class="answer-action row align-items-center">
        <div class="action-rating col-9 col-lg-7">
          <Rating :active="totalStar" :avg-star="item.avg_star" :number-rate="item.number_rate" @rating="userVote" :object-id="item.id" :user-id="item.created_by" />
        </div>
        <div class="action-more col-3 col-lg-5 text-right">
          <span v-if="authenticated && user.uuid !== item.created_by && item.is_guru === 0" @click="typeOf(level='ANSWER',item.id)" class="action pointer" :class="{'active': item.statusClaim}" :title="item.statusClaim ? 'Đã báo vi phạm' : 'Báo cáo vi phạm'">
            <span v-html="require('~/assets/mlearn/icons/answer/report.svg?raw')" />
            <span class="action-number d-none d-lg-inline-block">{{item.statusClaim ? 'Đã báo vi phạm' : 'Báo cáo vi phạm'}}</span>
          </span>
          <span v-if="authenticated && user.uuid === question.created_by" class="action pointer" :class="{'active': item.thanked}" title="Cảm ơn" @click="item.thanked ? '' : thankAnswerEvent(item.id);">
            <span v-html="require('~/assets/mlearn/icons/answer/thank.svg?raw')" />
            <span class="action-number d-none d-lg-inline-block">Cảm ơn</span>
          </span>
          <span v-if="authenticated && user.uuid === item.created_by" class="edit-answer pointer" @click="redirectAnwser(item.id)">Sửa</span>
        </div>
      </div>
      <div class="form-comment form-data align-items-center">
        <img class="avatar rounded-circle" :src="user && user.avatar ? user.avatar : NoAvatar" />
        <div class="content">
          <input v-if="authenticated" :disabled="isCommentAnswer" class="w-100" placeholder="Bình luận của bạn..." v-model="comment_answer" @keyup.enter="commentAnswer(item.id)" />
          <input v-else class="w-100" placeholder="Bình luận của bạn..." v-b-modal.login />
        </div>
        <span class="ml-2 ml-lg-3" @click="commentAnswer(item.id)" v-html="require('~/assets/mlearn/icons/comment/send-comment.svg?raw')"></span>
      </div>
      <div v-if="item.comments" class="list-comment scrollbar">
        <CommentItem v-for="it in item.comments" :key="it.id" :item="it" />
      </div>
      <div class="loadmore pointer pt-2 text-primary" v-if="item.comments.length < item.total_comments || display_loadmore_cmt_answer" @click="viewMoreCommentAnswer(item.id);">Xem thêm</div>
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'
import Rating from '~/components/mlearn/answer/rating.vue'
import CommentItem from '~/components/mlearn/question/comment.vue'
import Resource from '~/common/api/resource'
const qaResource = new Resource('qa')
const createRatingResource = new Resource('rating')
const commentResource = new Resource('comment')
const answerRatingResource = new Resource('qa/answer/rate')
export default {
  components: {
    Rating,
    CommentItem
  },
  props: {
    question: {
      type: Object,
      default () {
        return null
      }
    },
    item: {
      type: Object,
      default () {
        return null
      }
    },
    typeRating: {
      type: Object,
      default () {
        return null
      }
    },
    ratingValue: {
      type: Object,
      default () {
        return null
      }
    },
    uuidTypeRatingThanks: {
      type: String,
      default () {
        return ''
      }
    },
    uuidDefineDetailThank: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      NoAvatar,
      voteValue: ['Quá tệ', 'Bình thường', 'Tốt', 'Tuyệt vời', 'Xuất sắc'],
      rowsPerPage: '5',
      comment_answer: '',
      isCommentAnswer: false,
      display_loadmore_cmt_answer: false,
      curent_page_comment_answer: 1,
      isComment: true,
      options: {
        navbar: false,
        title: false,
        toolbar: {
          zoomIn: true,
          zoomOut: true,
          oneToOne: true,
          reset: false,
          rotateRight: true,
          rotateLeft: true
        }
      },
      tmp_detailQuestion: this.question
    }
  },
  computed: {
    ...mapState([
      'isMobile',
      'user',
      'authenticated'
    ]),
    totalStar () {
      if (!this.authenticated) { return this.item.avg_star }
      if (this.item.created_by === this.user.uuid) { return this.item.avg_star }
      if (!this.item.votes.is_vote) { return 0 }
      return this.item.avg_star
    }
  },
  methods: {
    async redirectAnwser (answerId) {
      const notfound = await this.checkQuestion()
      if (notfound) {
        return
      }
      const notfoundAnswer = await this.checkAnswer(answerId)
      if (notfoundAnswer) {
        return
      }
      this.$router.push('/tra-loi/' + this.question.id)
    },
    checkQuestion () {
      return new Promise((resolve) => {
        qaResource.get(this.question.id)
          .then((res) => {
            this.tmp_detailQuestion = res.data
            resolve(false)
          })
          .catch(() => {
            this.$notify.error({
              message: 'Không thể thực hiện thao tác. Câu hỏi đã bị xóa hoặc bị báo cáo vi phạm.',
              showClose: true,
              position: 'top-right'
            })
            resolve(true)
          })
      })
    },
    // Thank
    async thankAnswerEvent (objectUuid) {
      if (!this.authenticated) {
        return false
      }
      const notfound = await this.checkQuestion()
      if (notfound) {
        this.$notify({
          type: 'warning',
          message: 'Câu hỏi đã bị xóa hoặc bị báo cáo vi phạm'
        })
        return
      }
      const notfoundAnswer = await this.checkAnswer(objectUuid)
      if (notfoundAnswer) {
        this.$notify({
          type: 'warning',
          message: 'Câu trả lời đã bị xóa hoặc bị báo cáo vi phạm'
        })
        return
      }
      const postParam = {
        type: this.typeRating.answer,
        userUuid: this.user.uuid,
        objectUuid,
        ratingValue: this.ratingValue.thank,
        defineUuid: this.uuidTypeRatingThanks,
        defineDetailUuid: this.uuidDefineDetailThank
      }
      createRatingResource.store(postParam)
        .then((result) => {
          if (result.status === 200) {
            // eslint-disable-next-line vue/no-mutating-props
            this.item.thank++
            // eslint-disable-next-line vue/no-mutating-props
            this.item.thanked = true
          }
        })
    },
    async commentAnswer (answerId) {
      let comment = this.comment_answer || ''
      comment = comment.trim()
      if (!comment || this.isCommentAnswer) {
        return false
      }
      this.isCommentAnswer = true
      const notfound = await this.checkQuestion()
      if (notfound) {
        return
      }
      const notfoundAnswer = await this.checkAnswer(answerId)
      if (notfoundAnswer) {
        return
      }
      if (comment.length > 1000) {
        this.$notify.error({
          message: 'Vui lòng không nhập quá 1000 kí tự',
          showClose: true,
          position: 'top-right'
        })
        return
      }
      const dataSend = {
        type: this.typeRating.answer,
        userUuid: this.userUuid,
        objectUuid: answerId,
        content: comment.substring(0, 1000)
      }

      await setTimeout(() => {
        this.isComment = true
      }, 1000)
      if (this.isComment) {
        commentResource
          .store(dataSend)
          .then((response) => {
            if (response.status === 200) {
              const data = response.data
              data.uuid = data.id
              data.createdAt = data.createdAtPHP
              // eslint-disable-next-line vue/no-mutating-props
              this.item.comments.unshift(data)
              if (this.item.comments.length > 5) {
                this.display_loadmore_cmt_answer = true
              }
              // eslint-disable-next-line vue/no-mutating-props
              this.item.comments = this.item.comments.slice(0, 5)
            }
          })
          .catch(() => {
          })
          .finally(() => {
            this.comment_answer = ''
            this.isComment = false
            this.curent_page_comment_answer = 1
            this.isCommentAnswer = false
          })
      } else {
        this.$notify.error({
          title: 'Có lỗi',
          message: 'Vui lòng chờ 1 giây tiếp theo để bình luận'
        })
        this.isComment = false
      }
      this.comment_answer = ''
    },
    async viewMoreCommentAnswer (objectUuid) {
      const notfound = await this.checkQuestion()
      if (notfound) {
        return
      }
      const notfoundAnswer = await this.checkAnswer(objectUuid)
      if (notfoundAnswer) {
        return
      }
      if (!this.curent_page_comment_answer) {
        this.curent_page_comment_answer = 1
      } else {
        this.curent_page_comment_answer += 1
      }
      const postParam = {
        type: this.typeRating.answer,
        objectUuid,
        currentPage: this.curent_page_comment_answer,
        rowsPerPage: this.rowsPerPage
      }
      commentResource
        .list(postParam)
        .then((response) => {
          const data = response.data
          if (data) {
            // eslint-disable-next-line vue/no-mutating-props
            this.item.comments.push(...data)
            this.curent_page_comment_answer += 1
            if (this.item.comments.length === response.total) {
              this.display_loadmore_cmt_answer = false
            }
          }
        })
    },
    async typeOf (level, idQA) {
      if (this.item.statusClaim) { return }
      const notfound = await this.checkQuestion()
      if (notfound) {
        return
      }
      const typeOfObject = { level, idQA }
      this.$emit('report', typeOfObject)
    },
    // check status answer
    checkAnswer (answerId) {
      if (!this.tmp_detailQuestion.answers.length) {
        this.$notify.error({
          message: 'Câu trả lời không tồn tại hoặc đã bị báo cáo vi phạm',
          showClose: true,
          position: 'top-right'
        })
        return true
      }
      const check = this.tmp_detailQuestion.answers.find(item => item.id === answerId)
      if (!check) {
        this.$notify.error({
          message: 'Câu trả lời không tồn tại hoặc đã bị báo cáo vi phạm',
          showClose: true,
          position: 'top-right'
        })
        return true
      }
      return false
    },
    userVote (value) {
      if (!this.authenticated) { return }
      if (this.item.created_by === this.user.uuid) { return }
      if (!this.item.votes.is_vote) {
        const data = {
          answer_id: this.item.id,
          vote_star: value
        }
        answerRatingResource.store(data)
          .then((res) => {
            if (res.status === 200) {
              // eslint-disable-next-line vue/no-mutating-props
              this.item.votes.is_vote = 1
              // eslint-disable-next-line vue/no-mutating-props
              this.item.avg_star = res.data.avg_star
              // eslint-disable-next-line vue/no-mutating-props
              this.item.number_rate = res.data.number_rate
            }
          })
          .catch((err) => {
            this.$message({
              type: 'warning',
              message: err.response.data.message
            })
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .answer-item{
    .time-line{
      left: 4px;
      border-left: 1px solid #E2E2E2;
    }
    .time-line-dot{
      background-color: #e4e7ed;
      left: -1px;
      width: 12px;
      height: 12px;
      top: -5px;
    }
    .time-line-dot-end{
      background-color: #e4e7ed;
      left: -1px;
      width: 12px;
      height: 12px;
      bottom: 0px;
    }
    .comment{
      top: -3px;
      padding-top:26px;
      padding-bottom:30px;
      padding-left: 35px;
      margin-top: 16px;
      @media (max-width:1366px) {
        padding-top:10px;
        padding-bottom:0;
        padding-left: 20px;
        margin-top: 0;
      }
      @media (max-width:576px) {
        padding-top:16px;
        padding-bottom:0;
        padding-left: 0;
        margin-top: 0;
      }
      .user-info {
        .avatar{
          width:46px;
          height:46px;
          margin-right: 15px;
          @media (max-width:576px) {
            margin-right: 10px;
          }
        }
        .user-name{
          font-weight: bold;
          font-size: 16px;
          @media (max-width:1366px) {
            font-size: 15px;
          }
          @media (max-width:576px) {
            font-size: 14px;
          }
          .is-guru{
            margin-left: 10px;
            font-weight: 500;
            color: #868789;
            font-size: 14px;
            @media (max-width:576px) {
              font-size: 13px;
            }
          }
        }
        .time-ago{
          font-size: 12px;
          color: #65676B;
        }
        .guru-verify{
          color:#27AE60;
          font-size: 14px;
          @media (max-width:576px) {
            font-size: 13px;
          }
        }
      }
      .content-answer{
        padding-top:10px;
        font-size: 16px;
        @media (max-width:576px) {
          font-size: 14px;
        }
      }
      .image-answer{
        padding-top: 10px;
        img {
          border-radius: 8px;
          max-height: 300px;
          @media (max-width:1366px) {}
          @media (max-width:576px) {}
        }
      }
      .answer-action{
        padding-top: 15px;
        .action-rating{
          img{
            margin-right: 4px;
            &:last-child{
              margin-right: 0;
            }
          }
        }
        .action-more{
          @media (max-width:576px) {
            align-self: flex-start;
          }
          .action{
            cursor: pointer;
            margin-right: 10px;
            &:last-child{
              margin-right: 0;
            }
            .action-number{
              font-size: 14px;
              vertical-align: text-top;
            }
          }
          .edit-answer{
            color:var(--primary);
          }
        }
      }
      .form-comment{
        margin-top:20px;
        margin-bottom:15px;
        @media (max-width:1366px) {
          margin-top:10px;
          margin-bottom:10px;
        }
        @media (max-width:576px) {
          margin-top:10px;
          margin-bottom:10px;
        }
        .avatar{
          width: 36px;
          height: 36px;
          margin-right: 12px;
        }
        .content{
          input{
            background: #EBEBEB;
            border-radius: 22px;
            padding:14px 16px 13px;
            font-size: 14px;
            &::placeholder{
              color: #6B6D70;
            }
            @media (max-width:1366px) {
              padding:10px 12px;
            }
            @media (max-width:576px) {
              padding:10px 12px;
            }
          }
        }
      }
      .list-comment{
        max-height: 300px;
        @media (max-width:1366px) {
          max-height: 200px;
        }
        @media (max-width:576px) {
          max-height: 200px;
        }
      }
    }
    .line{
      border-bottom: 1px solid #E2E2E2;
      margin-left: 28px;
      @media (max-width:576px) {
        margin-left: 0;
      }
    }
  }
</style>
<style lang="scss">
  .answer-item{
    .action-more{
      .action{
        &.active{
          color: var(--primary);
        }
      }
    }
  }
</style>
